import React, { useState } from "react"

import SEO from "../components/seo"
import { Button } from "antd"
import HomeLayout from "../components/home/HomeLayout"
import { navigate } from "gatsby"

const NotFoundPage = () => {
  const [fadeOut, setfadeOut] = useState(false)

  return (
    <HomeLayout page="404" fadeOut={fadeOut} fadeOutAll={false}>
      <SEO title="404: Not found" />
      <h1>Sorry! We couldn't find that page.</h1>
      <Button
        onClick={() => navigate("/")}
        type="primary"
        size="large"
        shape="round"
      >
        Return Home
      </Button>
    </HomeLayout>
  )
}

export default NotFoundPage
